import apigeeIntegration from "./self-service-adapter";
import systemUserIntegration from "./self-service-system-adapter";

const api = apigeeIntegration("/device-return/v1");
const apiV2 = apigeeIntegration("/device-return/v2");
const systemUserApi = systemUserIntegration("/device-return/v1");
const systemUserApiV2 = systemUserIntegration("/device-return/v2");

//https://test-developer.corp.telenor.no/documentation/apis/device-return-v1/atest#overview--introduction

const DISABLE_CACHE = {
	headers: {
		"x-apicache-bypass": true,
	},
	cache: { override: true },
};

const searchIMEINumber = async (imeiNumber) =>
	systemUserApiV2.get(`/return/devices/${imeiNumber}?details=true`, {
		headers: {
			"x-telenor-dealer-id": 6662,
			"x-telenor-channel-name": "MTW",
		},
		timeout: 30000,
	});

const searchIMEINumberLoggedInUser = async (imeiNumber) => apiV2.get(`/return/devices/${imeiNumber}?details=true`);

const getGradingQuestions = async () => systemUserApi.get(`/questions/no`, { timeout: 30000 });

const getEstimatedValue = async (imeiNumber, device) =>
	systemUserApi.post(`/${imeiNumber}/grading`, device, { ...DISABLE_CACHE, timeout: 30000 });

const getDeviceReturnOrder = async (deviceReturnId) => systemUserApi.get(`/${deviceReturnId}`, { timeout: 30000 });

const getDamageCodes = async (deviceReturnId, language = "no") =>
	api.get(`/${deviceReturnId}/damagecodes/${language}`, { timeout: 30000 });

const submitInsuranceClaim = async (deviceReturnId, insuranceClaim) =>
	api.post(`/${deviceReturnId}/insuranceclaim`, insuranceClaim, { timeout: 30000 });

const submitOrder = async (deviceReturnId, order) =>
	api.post(`/${deviceReturnId}/submit?sendContractByMail=true&autoPostContractToPartner=true`, order, {
		timeout: 30000,
	});

const checkForCampaignBonus = async (deviceReturnId, gtin) =>
	api.post(
		`/${deviceReturnId}/campaignbonus`,
		{ gtin: gtin },
		{
			headers: {
				"x-telenor-dealer-id": 6662,
				"x-telenor-channel-name": "MineSider",
			},
			timeout: 30000,
		},
	);

const updateGrading = async (deviceReturnId, gtin) =>
	api.post(
		`/${deviceReturnId}/campaignbonus?updateGrading=true`,
		{ gtin: gtin },
		{
			headers: {
				"x-telenor-dealer-id": 6662,
				"x-telenor-channel-name": "MineSider",
			},
			timeout: 30000,
		},
	);

const cancelOrder = async (deviceReturnOrderId, reason) =>
	apiV2.delete(`/return/orders/${deviceReturnOrderId}?reason=${reason}`, {
		headers: {
			"x-telenor-dealer-id": 6662,
			"x-telenor-channel-name": "MineSider",
		},
		timeout: 30000,
	});

export default {
	searchIMEINumber,
	searchIMEINumberLoggedInUser,
	getGradingQuestions,
	getEstimatedValue,
	getDeviceReturnOrder,
	getDamageCodes,
	submitInsuranceClaim,
	submitOrder,
	checkForCampaignBonus,
	updateGrading,
	cancelOrder,
};
